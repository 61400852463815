
































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Settings16 from '@carbon/icons-vue/es/settings/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import Add16 from '@carbon/icons-vue/es/add/16'
import View16 from '@carbon/icons-vue/es/view/16'
import DocumentView16 from '@carbon/icons-vue/es/document--view/16'
import axios from 'axios'
import { ValidationObserver } from 'vee-validate'
import debounce from 'lodash/debounce'
import snakeCaseKeys from 'snakecase-keys'

import TablePagination from '@/partials/tables/Pagination.vue'
import NotificationMixin from '@/mixins/notificationMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import moment from 'moment'
import camelCaseKeys from 'camelcase-keys'

@Component({
  components: {
    TablePagination,
    DocumentView16,
    Settings16,
    TrashCan16,
    Edit16,
    Add16,
    View16,
    PasswordConfirmField,
    ValidationObserver,
    GenericModalDelete
  }
})
export default class UsersIndex extends Mixins(NotificationMixin, PermissionsMixin) {
  deleteModalIsOpened = false
  tableTitle = ''
  data = []
  filteredData = []
  response: Record<string, any> = {}

  deleteForm = {
    id: '',
    name: ''
  }

  form: Record<string, any> = {
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  searchString = ''
  currentPage = 0

  done = false
  created () {
    const { page, perPage, search } = this.$route.query

    if (page || search) {
      this.fetchUsers(+page, +perPage, search as string)
    } else {
      this.fetchUsers()
    }
  }

  membershipText (user: Record<string, any>) {
    const output = {
      sbqn: user.sbqn ?? '',
      memberSince: moment(user.memberSince).format('DD/MM/YYYY') ?? ''
    }

    return output.sbqn + ' ' + output.memberSince
  }

  onPageChange (pagination: Pagination) {
    if (this.done) {
      this.$router.push({
        query: {
          page: pagination.page.toString(),
          perPage: pagination.length.toString(),
          search: this.searchString
        }
      })
    }
  }

  @Watch('searchString')
  search = debounce((searchString) => {
    this.$router.push({
      query: {
        page: '1',
        search: searchString
      }
    })
  }, 300)

  @Watch('$route.query.page')
  @Watch('$route.query.search')
  onRouteChange () {
    const { page, perPage, search } = this.$route.query

    console.log(page, search)

    this.fetchUsers(+page, +perPage, search as string)
  }

  fetchUsers (page = 1, perPage = 20, search = '') {
    this.done = false
    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get('/users', {
      params: {
        'page[number]': page,
        ...perPageSnake,
        search
      }
    })
      .then(response => {
        this.data = response.data.data
        this.filteredData = camelCaseKeys(response.data.data, { deep: true })
        this.response = response.data
        this.tableTitle = `${this.response.total} usuários encontrados`
        if (this.$route.query.search) { this.tableTitle += ` para a busca '${this.$route.query.search}'` }
        this.currentPage = +this.$route.query.page
      })
      .finally(() => { this.done = true })
  }

  setDeleteForm (data: {name: string; id: string}) {
    const { name, id } = data
    this.deleteForm = { name, id }
  }

  postSubmit () {
    this.setNotification('success', `O usuário ${this.deleteForm.name} foi removido`)
    this.toggleModal('deleteModalIsOpened')
    this.fetchUsers()
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  resetDeleteForm () {
    this.deleteForm = {
      id: '',
      name: ''
    }
  }

  resetForm () {
    this.form = {
      confirm: '',
      errors: {
        confirm: ''
      }
    }
  }

  toggleModal (modalName: string) {
    this.$data[modalName] = !this.$data[modalName]
  }

  getProfileData (profile: Array<ProfileItem>, metaKey: string) {
    return profile.find((item) => item.metaKey === metaKey)?.metaValue
  }
}
